import { ActionType } from '../../../constants/ActionTypes';
export default function smtpReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_SMTP_BEGIN:
            return {
                ...state,
                inSavingProcess: false,
                inLoadingProcess: true
            }
        case ActionType.SET_SMTP_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.GET_PROJECT_SMTP_PROVIDERS_SUCCESS:
            return {
                ...state,
                data: action.payload || {},
                inLoadingProcess: false
            }
        case ActionType.GET_ALL_SMTP_PROVIDERS_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.SET_PROJECTS_SMTP_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_PROJECTS_SMTP_SUCCESS:
            return {
                ...state,
                inSavingProcess: false
            }
        default:
            return state;
    }
}
