import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { RoutePath } from '../../../constants/SitePaths'
import config from '../../../constants/Configs'
import Can from '../../../services/permisions/Can'
import { Browser } from '../../../services/helpers/Browser'
import { Menu } from 'antd'
import RolesHelper from '../../../services/permisions/RolesHelper'
const { SubMenu } = Menu;
class SettingsMenu extends React.Component {
  state = {
    selectedMenu: []
  }
  settings_sections = [
    { id: 1, icon: 'overview-bicon', size: 'fi', url: RoutePath.SETTINGS_OVERVIEW, title: this.props.intl.messages.overview },
    { id: 2, icon: 'language-bicon', name: 'projectSettings.settingsLanguages', size: 'fi', url: RoutePath.SETTINGS_LANGUAGES, title: this.props.intl.messages.languages },
    { id: 4, icon: 'currency-bicon', name: 'projectSettings.settingsCurrencies', size: 'fi', url: RoutePath.SETTINGS_CURRENCIES, title: this.props.intl.messages.currencies },
    { id: 3, icon: 'captcha-bicon', name: 'projectSettings.settingsCaptcha', size: 'fi', url: RoutePath.SETTINGS_CAPTCHA, title: this.props.intl.messages.captcha },
    // { id: 5, icon: 'payments-bicon', name: 'projectSettings.settingsPayments', size: 'fi', url: RoutePath.SETTINGS_PAYMENTS, title: this.props.intl.messages.payments },
    /*
    TODO: temporary hiding, should be deleted later.
    { id: 6, icon: 'messages-bicon', name: 'projectSettings.settingsSMS', size: 'fi', url: RoutePath.SETTINGS_SMS, title: this.props.intl.messages.sms },
    { id: 7, icon: 'mailbox-bicon', name: 'projectSettings.settingsSMTP', size: 'fi', url: RoutePath.SETTINGS_SMTP, title: this.props.intl.messages.smtp }, 
    */
    { id: 8, icon: 'domains-bicon', name: 'projectSettings.settingsDomains', size: 'fi', url: RoutePath.SETTINGS_DOMAINS, title: this.props.intl.messages.domains, alias: [RoutePath.SETTINGS_ADD_DOMAIN] },
    {
      id: 9, icon: 'products-bicon', name: 'projectSettings.settingsProducts', size: 'fi', url: `${RoutePath.SETTINGS_PRODUCTS}/sport`, title: this.props.intl.messages.products,
      items: [{
        id: 26,
        size: 'fi',
        url: `${RoutePath.SETTINGS_PRODUCTS}/sport`,
        name: 'settingsProducts',
        title: this.props.intl.messages.sportsbook
      }, 
      {
        id: 27,
        size: 'fi',
        name: 'settingsProducts',
        url: `${RoutePath.SETTINGS_PRODUCTS}/casino`,
        title: this.props.intl.messages.casino
      }
    ]
    },
    
    { id: 10, icon: 'third-party-integrations-bicon', name: 'projectSettings.settings3rdparty', size: 'fi', url: RoutePath.THIRD_PARTY_INTEGRATIONS, title: this.props.intl.messages.third_party_integrations },
    {
      id: 11,
      icon: 'custom-code-bicon',
      size: 'fi',
      name: 'subpage.customCodes',
      url: `${RoutePath.CUSTOM_SCRIPTS}/script`,
      title: this.props.intl.messages.custom_codes,
      className: 'settings_nav_menu',
      items: [{
        id: 12,
        size: 'fi',
        url: `${RoutePath.CUSTOM_SCRIPTS}/script`,
        name: 'settingsCustomScripts',
        title: this.props.intl.messages.custom_script
      }, {
        id: 13,
        size: 'fi',
        name: 'settingsCustomStyles',
        url: `${RoutePath.CUSTOM_SCRIPTS}/style`,
        title: this.props.intl.messages.custom_styles
      }]
    },
    {
      id: 14,
      icon: 'seo-bicon',
      size: 'fi',
      url: `${RoutePath.SETTINGS_SEO}`,
      title: this.props.intl.messages.seo,
      name: 'projectSettings.settingsSEO',
      className: 'settings_nav_menu',
      items: [{
        id: 15,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO}`,
        title: this.props.intl.messages.seo_settings,
      }, {
        id: 16,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO_SITEMAP}`,
        title: this.props.intl.messages.seo_sitemap,
      },
      {
        id: 17,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO_SOCIAL}`,
        title: this.props.intl.messages.seo_social,
      }, {
        id: 18,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO_SCHEMA_MARKUP}`,
        title: this.props.intl.messages.seo_schema_markup,
      }]
    },
    { id: 19, icon: 'add-ons-bicon', name: 'projectSettings.settingsAddOns', size: 'fi', url: RoutePath.ADD_ONS, title: this.props.intl.messages.add_ons },
    {
      id: 20,
      icon: 'settings-bicon',
      name: 'subpage.general settings',
      size: 'fi',
      url: `${RoutePath.SETTINGS_LICENSE}`,
      title: this.props.intl.messages.general_settings,
      className: 'settings_nav_menu',
      items: [{
        id: 21,
        size: 'fi',
        name: 'settingsLicense',
        url: `${RoutePath.SETTINGS_LICENSE}`,
        title: this.props.intl.messages.licenses,
      },
      {
        id: 22,
        size: 'fi',
        name: 'settingsReferAFriend',
        url: `${RoutePath.SETTINGS_REFERRAL_ID}`,
        title: this.props.intl.messages.refer_a_friend,
      }, 
      {
        id: 24,
        size: 'fi',
        url: `${RoutePath.SETTINGS_AGE_RESTRICTION}`,
        name: 'settingsAgeRestriction',
        title: this.props.intl.messages.age_restriction,
      }]
    },
    { id: 25, icon: 'dev-tool-bicon', name: 'SuperAdmin', size: 'fi', url: RoutePath.DEV_TOOLS, title: this.props.intl.messages.dev_tool },
  ];
  componentDidMount() {
    this.openSelectedMenu();
    
    config.NEW_PLAT && this.settings_sections[10].items.push({
      id: 23,
      size: 'fi',
      name: 'settingsAffiliate',
      url: `${RoutePath.SETTINGS_AFFILIATE}`,
      title: this.props.intl.messages.affiliate,
    })
    config.NEW_PLAT && this.settings_sections[10].items.sort((a,b) => a.id-b.id)
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.openSelectedMenu();
    }
  }
  openSelectedMenu() {
    const openedMenu = this.settings_sections.filter(menuItem => this.props.location.pathname === menuItem.url + '/' + this.props.match.params.id ||
      (menuItem.items && menuItem.items.filter(subItem => this.props.location.pathname === subItem.url + '/' + this.props.match.params.id).length) ||
      (menuItem.alias && menuItem.alias.filter((a) => this.props.location.pathname.includes(a)).length))
    openedMenu[0] && this.setState({
      selectedMenu: [openedMenu[0].id + ""]
    })
  }
  redirectToSetting(menuItem) {
    const companyId = Browser.getURLParamValue('cid');
    const projectId = this.props.match.params.id;
    this.props.history.push(`${menuItem.url}/${projectId}?cid=${companyId}&p=${projectId}`);
  }

  handleTitleClick = (item) => {
    this.setState({
      selectedMenu: this.state.selectedMenu.indexOf(item.key) >= 0 ? this.state.selectedMenu.filter((i) => i !== item.key) :
        [...this.state.selectedMenu, item.key]
    })
  }

  render() {
    const defaultSubItems = [];
    const settings_menu = this.settings_sections.filter(item => ((!item.name && !RolesHelper.isAffilateUser()) || Can({
      I: "view",
      a: `${item.name}`,
      fallback: false,
      children: true
    }))).map(menuItem => {
      if (menuItem.hasOwnProperty('items')) {
        return (
          <SubMenu
            className='sidenav--collapsed-items'
            onTitleClick={this.handleTitleClick}
            key={menuItem.id}
            title={
              <>
                <i className={`${menuItem.size} ${menuItem.icon}`}></i>
                <span className={`${menuItem.className} tb--mh-10`}>{menuItem.title}</span>
              </>
            }
          >
            {
              menuItem.items && menuItem.items.filter(item => Can({
                I: "view",
                a: `projectSettings.${item.name}`,
                fallback: false,
                children: true
              })).map((subMenuItem, key) => {
                const isSelectedSubItem = this.props.location.pathname === subMenuItem.url + '/' + this.props.match.params.id;
                isSelectedSubItem && defaultSubItems.push(subMenuItem.id + "")
                return (
                  <Menu.Item className='sidenav--item' onClick={this.redirectToSetting.bind(this, subMenuItem, key, menuItem)} key={subMenuItem.id}>
                    {subMenuItem.title}
                  </Menu.Item>
                )
              })
            }
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={menuItem.id} onClick={this.redirectToSetting.bind(this, menuItem)}
            className={`${(this.props.location.pathname === menuItem.url + '/' + this.props.match.params.id || (menuItem.alias && menuItem.alias.filter((a) => this.props.location.pathname.includes(a)).length)) ? 'settings_selected_item sidenav--selected-item' : ''} tb--flex tb--align-center sidenav--item settings_menu_item`}>
            <i className={`${menuItem.size} ${menuItem.icon}`}></i>
            <span className={`${menuItem.className} tb--mh-10`}>{menuItem.title}</span>
          </Menu.Item>
        )
      }
    })
    return (
      <div className="sidenav">
        {RolesHelper.isAffilateUser() ?
          <></>
          : <>
            <a href='/projects' className='go_to_dashboard tb--flex tb--align-center'>
              <i className="arrow-left-bicon"></i>
              <span>{this.props.intl.messages.dashboard}</span>
            </a>
            <Can I="view" a="page.uiCustomization">
              <a key={RoutePath.CUSTOMIZE} className='tb--edit-site_btn tb--flex tb--justify-center tb--align-center' onClick={this.redirectToSetting.bind(this, { url: RoutePath.CUSTOMIZE })}>
                <i className="fi edit-bicon"></i>
                <span>{this.props.intl.messages.edit_site}</span>
              </a>
              <div className='edit--site-border'></div>
            </Can>
          </>
        }
        <Menu
          openKeys={this.state.selectedMenu}
          selectedKeys={defaultSubItems}
          mode="inline"
        >
          {settings_menu}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    builder: state.builder
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return ({
    dispatch
  })
}

export default injectIntl(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsMenu)));