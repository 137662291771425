import { ActionType } from '../../../constants/ActionTypes';
export default function smsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROJECT_SMS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_PROJECS_SMS_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.GET_SMS_BEGIN:
            return {
                ...state,
                inSavingProcess: false,
                inLoadingProcess: true
            }
        case ActionType.SET_SMS_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.SET_PROJECTS_SMS_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_PROJECTS_SMS_SUCCESS:
            return {
                ...state,
                inSavingProcess: false,
                data: {
                    ...state.data,
                    providerType: Number(action.payload.providerType),
                    key1: action.payload.key1,
                    key2: action.payload.key2,
                    sender: action.payload.sender
                }

            }
        default:
            return state;
    }
}
