const template7 = {
    banners: {
        sections: {
            1: {
                animationTypes: ['Horizontal', 'Fade'],
                pagination: true,
                autoplay: true,
                arrows: true,
                counter: true
            },
            2: {
                animationTypes: ['Horizontal'],
                autoplay: true,
                arrows: true,
                counter: false
            },
            3: {
                animationTypes: ['Horizontal', 'Fade'],
                pagination: true,
                autoplay: true,
                arrows: false,
                counter: false
            },
            5: null
        },
        pages: {
            Mainpage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    home_page_main_section: {
                        width: 1854,
                        height: 520
                    },
                    home_page_secondary_section: {
                        width: 696,
                        height: 287
                    },
                    home_page_featured_banner_section: {
                        width: 1440,
                        height: 323,
                        gridAvailable: true
                    },
                    home_page_recommended_section: {
                        width: 343,
                        height: 230,
                        gridAvailable: true
                    }, 
                    home_page_top_casino_games_section: {
                        width: 343,
                        height: 230,
                        gridAvailable: true
                    }
                }
            },
            Casinopage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    casino_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            CasinoLobby: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    casino_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            LiveCasinopage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    livecasino_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            TVGamespage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    tvgames_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            VirtualSportpage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    virtualsport_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            FastGamespage: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    fastgames_page_main_section: {
                        width: 1903,
                        height: 1120
                    },
                }
            },
            CasinoTournaments: {
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    casino_tournaments_page_main_section: {
                        width: 1920,
                        height: 900
                    },
                }
            },
            MyProfile: {
                auth: true,
                settings: {
                    defaultAnimationSpeed: 5,
                    minAnimationSpeed: 0.5,
                    maxAnimationSpeed: 15
                },
                sections: {
                    myprofile_popup_main_section: {
                        width: 1329,
                        height: 90
                    },
                }
            },
            Registration: {
                auth: true,
                type: 1,
                speceficType: 1,
                settings: {
                },
                sections: {
                    registration_popup_main_section: {
                        width: 992,
                        height: 90
                    },
                }
            },
            Login: {
                auth: true,
                speceficType: 1,
                settings: {
                },
                sections: {
                    login_popup_main_section: {
                        width: 992,
                        height: 90
                    },
                }
            },
        }
    },
    fonts: {
        fontFamily: {
            title: 'font_family',
            name: 'font-family-1',
            defaultValueIndex: 0,
            values: [
                { name: "'Roboto', sans-serif", label: "Roboto", sampleText: 'roboto.svg' },
                { name: "Arial, Helvetica, sans-serif", label: "Arial", sampleText: 'arial.svg' },
                { name: "Impact, Charcoal, sans-serif", label: "Impact", sampleText: 'impact.svg' },
                { name: "Tahoma, Geneva, sans-serif", label: "Tahoma", sampleText: 'tahoma.svg' },
                { name: "Trebuchet MS, Helvetica, sans-serif", label: "Trebuchet MS", sampleText: 'trebuchet_ms.svg' },
                { name: "Verdana, Geneva, sans-serif", label: "Verdana", sampleText: 'verdana.svg' },
                { name: "MS Sans Serif, Geneva, sans-serif", label: "MS Sans Serif", sampleText: 'ms_sans_serif.svg' },
                { name: "Curier New, Courier, monospace", label: "Curier New", sampleText: 'courier_new.svg' },
                { name: "Lucida Console, Monaco, monospace", label: "Lucida Console", sampleText: 'lucida_console.svg' },
                { name: "Lucida Sans Unicode,Lucida Grande, monospace", label: "Lucida Sans Unicode", sampleText: 'lucida_sans_unicode.svg' },
                { name: "Georgia, serif", label: "Georgia", sampleText: 'georgia.svg' },
                { name: "Times New Roman, Times, serif", label: "Times New Roman", sampleText: 'times_new_roman.svg' },
                { name: "Palatino Linotype,Book Antiqua,Palatino, serif", label: "Palatino Linotype", sampleText: 'palatino_linotype.svg' },
                { name: "Comic Sans MS, cursive", label: "Comic Sans MS", sampleText: 'comic_sans_ms.svg' },
                { name: "'IRANSans', sans-serif", label: "Iranian Sans", sampleText: 'iran_sans.svg' }
            ]
        }
    },
    cornerRadius: {
        'btn-radius-top-left': '2',
        'btn-radius-top-right': '2',
        'btn-radius-bottom-right': '2',
        'btn-radius-bottom-left': '2',

        'input-radius-top-left': '2',
        'input-radius-top-right': '2',
        'input-radius-bottom-right': '2',
        'input-radius-bottom-left': '2'
    },
    colors: {
        brandColors: {
            title: "brand_colors",
            list: [
                {
                    name: 'brand-color-1',
                    value: '#282b32',
                    visible: true
                },
                {
                    name: 'brand-color-2',
                    value: '#2f323a',
                    visible: true
                },
                {
                    name: 'brand-color-text',
                    value: '#2f323a',
                    visible: true
                }
            ]
        },

        backgoundColors: {
            title: "background_colors",
            autoGenerate: true,
            bottomAxis: { start: 0, end: 50 },
            topAxis: { start: 50, end: 100 },
            list: [
                {
                    name: 'background-color-1',
                    value: '#3f434e',
                    visible: true,
                    generationStep: 0,
                },
                {
                    name: 'background-color-2',
                    value: '#282b32',
                    visible: true,
                    generationStep: 5,
                },
                {
                    name: 'background-color-3',
                    value: '#2f323a',
                    visible: true,
                    generationStep: 10,
                },
                {
                    name: 'background-color-4',
                    value: '#454b57',
                    visible: true,
                    generationStep: 15,
                },
                {
                    name: 'background-color-5',
                    value: '#4a505c',
                    visible: true,
                    generationStep: 20,
                },
                {
                    name: 'background-color-6',
                    value: '#515765',
                    visible: true,
                    generationStep: 25,
                }
            ]
        },
        textColors: {
            title: "text_colors",
            autoGenerate: true,
            bottomAxis: { start: 40, end: 60 },
            topAxis: { start: 60, end: 100 },
            list: [
                {
                    name: 'text-color-1',
                    value: '#f3f4f5',
                    visible: true,
                    generationStep: 0
                },
                {
                    name: 'text-color-2',
                    value: '#edeff0',
                    visible: true,
                    generationStep: 20
                },
                {
                    name: 'text-color-3',
                    value: '#e0e2e5',
                    visible: true,
                    generationStep: 30
                },
                {
                    name: 'text-color-4',
                    value: '#ccd0d5',
                    visible: true,
                    generationStep: 40
                }
            ]
        }
    },
    backgroundImage: true,
    timezone: {
        allowPositionChange: false,
    }
}
export default template7;