import { ActionType } from '../../../constants/ActionTypes';

export default function allSettingsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_ALL_SYSTEM_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            }
        case ActionType.GET_PROJECS_ALL_CAPTCHA_PROVIDERS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    captcha: action.payload
                }
            }
        case ActionType.GET_ALL_CURRENCIES_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    currencies: action.payload
                }
            }

        case ActionType.GET_ALL_LANGUAGES_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    languages: action.payload
                }
            }
        case ActionType.GET_SMS_PROVIDERS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    sms: action.payload
                }
            }
        case ActionType.GET_ALL_SMTP_PROVIDERS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    smtp: action.payload
                }
            }
        case ActionType.GET_ALL_EXTERNAL_SERVICES_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    externalServices: action.payload
                }
            }
            case ActionType.GET_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    countries: action.payload
                }
            }
        default:
            return state;
    }
}
