import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { RoutePath } from '../../constants/SitePaths';
import Can from '../../services/permisions/Can';
import { Menu } from 'antd'
let selectedKeys = []
class ProfileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    redirectToProfile(url) {
        this.props.history.push(url);
    }

    toggle(id, e) {
        e.stopPropagation();
        this.setState({ [id]: !this.state[id] })
    }

    render() {
        const profile_sections = [
            { id: 1, icon: 'profile-user-bicon', name: "userMyProfile", size: 'fi', url: RoutePath.PROFILE, title: this.props.intl.messages.myProfile },
            { id: 2, icon: 'role-bicon', name: "userRoles", size: 'fi', url: RoutePath.ROLES, title: this.props.intl.messages.roles },
            { id: 3, icon: 'bank-bicon', name: "SuperAdmin", size: 'fi', url: RoutePath.COMPANIES, title: this.props.intl.messages.companies },
            { id: 4, icon: 'globe-bicon', name: "userUsers", size: 'fi', url: RoutePath.USERS, title: this.props.intl.messages.users },
        ];

        const profile_menu = profile_sections.filter(item => {
            return !item.name || Can({
                I: "view",
                a: item.name !== 'SuperAdmin' ? `userManagement.${item.name}` : item.name,
                fallback: false,
                children: true
            })
        }).map(menuItem => {
            selectedKeys = this.props.location.pathname === menuItem.url || (this.props.location.pathname.match(`^${menuItem.url}`)) ? [menuItem.id + ''] : selectedKeys
            return (
                <Menu.Item key={menuItem.id} onClick={this.redirectToProfile.bind(this, menuItem.url)}
                    className={`tb--flex tb--align-center`}>
                    <i className={`${menuItem.size} ${menuItem.icon}`}></i>
                    <span className={`${menuItem.className} tb--mh-10`}>{menuItem.title} </span>
                </Menu.Item>
            )
        });

        return (
            <div className="sidenav">
                <Menu
                    className='tb--pt-10'
                    onOpenChange={this.onOpenChange}
                    defaultOpenKeys={['1']}
                    selectedKeys={selectedKeys}
                    mode="inline"
                >
                    {profile_menu}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        builder: state.builder
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return ({
        dispatch
    })
};

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu)));