import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl'

class Copyright extends React.Component {

    render() {
        return (
            <div className="copy">{this.props.intl.messages.copyright_text}</div>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default injectIntl(connect(
    mapStateToProps,
    null
)(Copyright));



