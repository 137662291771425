import { ActionType } from '../../../constants/ActionTypes';
import { Utils } from '../../../services/Utils';

export default function bannersReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_BANNERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                saving: false,
                data: []
            }
        case ActionType.GET_BANNERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ActionType.GET_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ActionType.ADD_BANNER_BEGIN:
        case ActionType.SET_SECTION_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.CHANGE_BANNER_SECTION_VISIBILITY_BEGIN:
        return {
            ...state,
            loading: true,
        }
        case ActionType.ADD_BANNER_SUCCESS:
        case ActionType.EDIT_BANNER_SUCCESS:
        case ActionType.ADD_BANNER_ERROR:
        case ActionType.SET_SECTION_ERROR:
        case ActionType.SET_SECTION_SUCCESS:
            return {
                ...state,
                saving: false
            }

        case ActionType.CHANGE_BANNER_SECTION_VISIBILITY_ERROR:
        case ActionType.CHANGE_BANNER_SECTION_VISIBILITY_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_SECTIONS_BEGIN:
            return {
                ...state,
                sections: [],
                sectionsLoading: true
            }
        case ActionType.GET_SECTIONS_ERROR:
            return {
                ...state,
                sectionsError: action.payload,
                sectionsLoading: false

            }
        case ActionType.GET_SECTIONS_SUCCESS:
            return {
                ...state,
                sections: action.payload,
                sectionsLoading: false
            }
        case ActionType.DELETE_BANNER_BEGIN:
            return {
                ...state,
                deleting: true
            }
        case ActionType.DELETE_BANNER_ERROR:
            return {
                ...state,
                deleting: false
            }


        case ActionType.GET_GRID_LAYOUT_BEGIN:
            return {
                ...state,
                isLoading: true,
                gridLayout: {},
            }
        case ActionType.GET_GRID_LAYOUT_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case ActionType.GET_GRID_LAYOUT_SUCCESS:
            return {
                ...state,
                gridLayout: action.payload,
                isLoading: false,
            }
        case ActionType.UPDATE_BANNER_GRID_LAYOUT_BEGIN:
            return {
                ...state,
                isSaving: true,
            }
        case ActionType.UPDATE_BANNER_GRID_LAYOUT_ERROR:
            return {
                ...state,
                isSaving: false,
            }
        case ActionType.UPDATE_BANNER_GRID_LAYOUT_SUCCESS:
            return {
                ...state,
                isSaving: false,
            }
        case ActionType.DELETE_BANNER_SUCCESS:
            let data = state.data.filter(banner => banner.id !== action.payload);
            return {
                ...state,
                data: data,
                deleting: false
            }
        case ActionType.MOVE_BANNER_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.MOVE_BANNER_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.MOVE_BANNER_SUCCESS:
            const menuItemIndex = state.data.findIndex(menuItem => menuItem.id === action.payload.bannerId);
            const movedBanners = Utils.move(menuItemIndex, action.payload.order - 1, ...state.data);
            return {
                ...state,
                loading: false,
                data: movedBanners
            }
        case ActionType.CHECK_BANNER_NAME_BEGIN:
        case ActionType.CHECK_BANNER_NAME_ERROR:
        case ActionType.CHECK_BANNER_NAME_SUCCESS:
            return {
                ...state
            }
        default:
            return state;
    }
}
