import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl'
import { Layout, Select } from 'antd';
import { logout } from '../../actions/UserActions';
import { getProject, getProjects } from '../../actions/projects/ProjectsActions'

import { Auth } from '../../services/Auth';
import RolesHelper from '../../services/permisions/RolesHelper';
import { RoutePath } from '../../constants/SitePaths';
import AccountMenu from './AccountMenu';
import ProjectStateEnum from '../../constants/ProjectStateEnum'

const { Header } = Layout;
const { Option } = Select;

class SiteHeader extends React.Component {

    componentDidMount() {
        const { isSettingsHeader } = this.props;
        const projectId = this.props.match.params.id;
        isSettingsHeader && this.props.dispatch(getProjects()).then(() => {
            projectId && this.props.dispatch(getProject(projectId));
        });

    }

    handleChangeProject = (project) => {
        this.props.history.push(`${RoutePath.SETTINGS_OVERVIEW}/${project.key}?cid=${project.id}&p=${project.key}`);
        window.location.reload(false);
    }

    render() {
        const { selectedProject, isSettingsHeader } = this.props;
        const { allProjects } = this.props.builder.projects;
        const { projects = [] } = allProjects;

        const partnersOptions = (
            projects && projects.filter(project => project.state !== ProjectStateEnum.Blocked)
            .sort( (a, b) => { return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0) })
            .map(project => (
                <Option className='settings--partner' value={project.name} id={project.parrentPartnerId} key={project.id} >
                    {project.name}
                </Option>
            ))
        );

        if (!this.props.user.isLoggedIn && !Auth.isAuthenticated()) {
            return <Redirect to='/login' />;
        }

        return (
            <Header className="header tb--flex tb--align-center tb--justify-between">
                <div>
                    {RolesHelper.isAffilateUser() ?
                        <div></div>
                        : <Link to={"/projects"}>
                            <div className="logo" />
                        </Link>
                    }
                </div>

                <div className='tb--flex tb--align-center'>
                    {
                        isSettingsHeader && (
                            <Select showSearch key={selectedProject.name} defaultValue={selectedProject.name} className='customization--partners'
                                onChange={(value, selectedProject) => this.handleChangeProject(selectedProject)}>
                                {partnersOptions}
                            </Select>
                        )
                    }
                    {isSettingsHeader && <div className='customization--preview tb--flex tb--align-center'>
                        <a {...(selectedProject && selectedProject.domain ? {} : { className: "tb--hidden-block" })} target="_blank" href={`//${selectedProject && selectedProject.domain}?prev=${new Date().getTime()}`}>
                            <span className='eye-bicon-span'><i className="eye-bicon"></i></span>
                            {this.props.intl.messages.preview}
                        </a>
                    </div>}
                    <AccountMenu showName={true} isSettingsHeader={isSettingsHeader} />
                </div>
            </Header>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return ({
        logout: () => {
            dispatch(logout());
        },
        dispatch
    });
}

const mapStateToProps = state => {
    return {
        user: state.user,
        selectedProject: state.builder.projects.selectedProject,
        builder: state.builder,
    }
}

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteHeader)));



